import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SubtipoDocument } from 'src/app/core/models/subtipos/subtipo-document';
import { TipoDocument } from 'src/app/core/models/tipo/tipo-document';
import { DocumentSubTypeService } from 'src/app/services/configCustodiaServices/DocumentSubTypeService/document-sub-type.service';

@Component({
  selector: 'app-subtipos',
  templateUrl: './subtipos.component.html',
  styleUrls: ['./subtipos.component.scss']
})
export class SubtiposComponent implements OnInit {

  public subTiposDocument: SubtipoDocument[] = [];
  public subTipoDocument: SubtipoDocument;
  public docDialog: boolean = false;
  public submit: boolean = false;
  public loading: boolean = false

  constructor(private service: DocumentSubTypeService,
    private confirmationService: ConfirmationService,
    private messageService : MessageService,
    private route: Router) { }

  ngOnInit(): void {
    this.getAll()
  }

  getAll() {
    this.loading = true;
    this.service.getAllTipos().subscribe(
      data => {
        console.log(data)
        this.subTiposDocument = data
        this.subTiposDocument.sort((a, b) => a.subTypeId - b.subTypeId)
        this.loading = false
      },
      error => { 
        console.log(error) 
        this.loading = false
      }
    )
  }

  editDoc(doc: SubtipoDocument) {
    this.route.navigate(['home/config/subtipos/edit', doc.subTypeId]);
  }

  saveDoc() {
    console.log(this.subTipoDocument)
    this.service.updateDocType(this.subTipoDocument).subscribe(
      data => {
        this.getAll()
      }
      , error => console.log(error)
    )
    this.docDialog = false;
    this.submit = true;
  }

  deleteDoc(doc: SubtipoDocument) {
    console.log(doc)
    this.confirmationService.confirm({
      message: 'Esta seguro que quiere eliminar el documento ' + doc.documentSubtypeDesc + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.deleteDocType(doc.subTypeId).subscribe(
          data => {
            console.log(data)
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tipo Documento eliminado correctamente' });
            this.getAll()
          },
          error => console.log(error)
        )
      }
    })
  }

  openNew() {
    this.route.navigate(['home/config/subtipos/edit', 0]);
  }

  cancel() {
    this.docDialog = false;
    this.submit = false;
  }

}
