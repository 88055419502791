<bc-menu>
    <bc-menu-drawer>
        <bc-menu-item path='#' identifierContent='id1' text='Configuración Custodia' iconName='settings'></bc-menu-item>
        <bc-menu-item path='#' identifierContent='id2' text='F.E Corporativa' iconName='data'></bc-menu-item>
    </bc-menu-drawer>
    <bc-menu-main>
        <bc-menu-body identifier='id1'>
            <bc-menu-column>
                <bc-menu-section title='Configuración'>
                    <bc-menu-option text='Subtipos' [routerLink]="['config/subtipos']"></bc-menu-option>
                    <bc-menu-option text='Tipos' [routerLink]="['config/tipos']"></bc-menu-option>
                    <bc-menu-option text='Atributos' [routerLink]="['config/atributos']"></bc-menu-option>
                </bc-menu-section>
            </bc-menu-column>
        </bc-menu-body>
        <bc-menu-body identifier='id2'>
            <bc-menu-column>
                <bc-menu-section title='Reportes'>
                    <bc-menu-option text='Trazabilidad' [routerLink]="['trazabilidad']"></bc-menu-option>
                </bc-menu-section>
            </bc-menu-column>
        </bc-menu-body>
    </bc-menu-main>
    <div class="bc-content-body">
        <router-outlet></router-outlet>
    </div>
</bc-menu>