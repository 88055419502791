import { NgModule } from '@angular/core';
import { PublicRoutingModule } from './public-routing.module';
import { CoreModule } from '../core/core.module';
import { BcIconModule } from 'bancolombia-design-system/bc-icon';


import { PublicComponent } from './public.component';
import { TrazabilidadComponent } from './home/firmaElectronica/reportes/trazabilidad/trazabilidad.component';
import { SubtiposComponent } from './home/configCustodia/subtipos/subtipos.component';
import { TiposComponent } from './home/configCustodia/tipos/tipos.component';
import { AtributosComponent } from './home/configCustodia/atributos/atributos.component';
import { HttpClientModule } from '@angular/common/http';
import { SubtipoEditComponent } from './home/configCustodia/subtipos/subtipo-edit/subtipo-edit.component';






@NgModule({
  declarations: [
    PublicComponent,
    TrazabilidadComponent,
    SubtiposComponent,
    TiposComponent,
    AtributosComponent,
    SubtipoEditComponent
  ],
  imports: [
    PublicRoutingModule,
    CoreModule,
    BcIconModule.forRoot({
      path: 'https://library-sdb.apps.bancolombia.com/2.0.22',
    }),
  ],
  providers: [
    HttpClientModule
  ]
})
export class PublicModule { }
