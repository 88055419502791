<p-toast></p-toast>
<p-card>
    <div class="container">
        <div class="title">
            <div class="titulo">
                <strong>Atributos</strong>
            </div>
        </div>
        <div class="table">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                </ng-template>
            </p-toolbar>
            <br>
            <p-table #dt [value]="attributeDocuments" [lazy]="true" [loading]="loading" [rowHover]="true" dataKey="attributteId">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th>Id Atributo</th> -->
                        <th>Atributo</th>
                        <th>Descripcion</th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Actualizado Por</th>
                        <th>Fecha Actualizacion</th>
                        <th class="buttonTable">Editar</th>
                        <!-- <th class="buttonTable"></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr>
                        <!-- <td>{{doc.attributteId}}</td> -->
                        <td>{{doc.attribute}}</td>
                        <td>{{doc.description}}</td>
                        <td>{{doc.audit.createdBy}}</td>
                        <td>{{doc.audit.creationDate}}</td>
                        <td>{{doc.audit.lastUpdatedBy}}</td>
                        <td>{{doc.audit.lastUpdateDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-mr-2 p-button-warning"
                                (click)="editDoc(doc)"></button>
                        </td>
                        <!-- <td>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteDoc(doc)"></button>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
<p-dialog [(visible)]="docDialog" [style]="{width: '600px'}" header="Detalle Documento" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="attribute">Atributo</label>
            <textarea id="attribute" pInputTextarea [(ngModel)]="attributeDocument.attribute" required
                rows="3" cols="20"></textarea>
        </div>
        <div class="p-field">
            <label for="description">Descripcion</label>
            <input type="text" pInputText id="description" [(ngModel)]="attributeDocument.description"
                required autofocus />
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
        <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveAttribute()"></button>
    </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}" acceptLabel="Si"></p-confirmDialog>
