import { Component, OnInit } from '@angular/core';
import { TipoDocument } from 'src/app/core/models/tipo/tipo-document';
import { DocumentTypeService } from 'src/app/services/configCustodiaServices/DocumentTypeService/document-type-service.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';



@Component({
  selector: 'app-tipos',
  templateUrl: './tipos.component.html',
  styleUrls: ['./tipos.component.scss']
})
export class TiposComponent implements OnInit {

  public tiposDocument: TipoDocument[] = [];
  public tipoDocument: TipoDocument;
  public docDialog: boolean = false;
  public submit: boolean = false;
  public loading: boolean = false
  public userName: string;

  constructor(private service: DocumentTypeService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAll()
    this.userName = sessionStorage.getItem('user')
  }

  getAll() {
    this.loading = true;
    this.service.getAllTipos().subscribe(
      data => {
        console.log(data)
        this.tiposDocument = data;
        this.tiposDocument.sort((a, b) => a.docTypeId - b.docTypeId)
        this.loading = false
      },
      error => {
        console.log(error)
        this.loading = false
      }
    )
  }

  editDoc(doc: TipoDocument) {
    this.tipoDocument = { ...doc }
    this.docDialog = true
  }

  saveDoc() {
    if (this.tipoDocument.docTypeId != null) {
      this.updateDoc()
    } else {
      this.createDoc()
    }
  }

  updateDoc() {
    const date = new Date()
    console.log(date)
    this.tipoDocument.audit.lastUpdatedBy = this.userName
    this.tipoDocument.audit.lastUpdateDate = date
    console.log(this.tipoDocument)
    this.service.updateDocType(this.tipoDocument).subscribe(
      data => {
        this.getAll()
      }
      , error => console.log(error)
    )
    this.docDialog = false;
    this.submit = true;
  }

  createDoc() {
    const date = new Date()
    console.log(date)
    this.tipoDocument.audit.createdBy = this.userName
    this.tipoDocument.audit.creationDate = date
    this.tipoDocument.audit.lastUpdatedBy = this.userName
    this.tipoDocument.audit.lastUpdateDate = date
    console.log(this.tipoDocument)
    this.service.createDocumentType(this.tipoDocument).subscribe(
      data => {
        this.getAll()
      }
      , error => console.log(error)
    )
    this.docDialog = false;
    this.submit = true;
  }

  deleteDoc(doc: TipoDocument) {
    console.log(doc)
    this.confirmationService.confirm({
      message: 'Esta seguro que quiere eliminar el documento ' + doc.docType + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.deleteDocType(doc.docTypeId).subscribe(
          data => {
            console.log(data)
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tipo Documento eliminado correctamente' });
            this.getAll()
          },
          error => console.log(error)
        )
      }
    })
  }

  openNew() {
    this.tipoDocument = {
      docType: null,
      docTypeDescription: '',
      audit: {
        createdBy: '',
        creationDate: '',
        lastUpdatedBy: '',
        lastUpdateDate: ''
      }
    }
    this.docDialog = true
  }

  cancel() {
    this.docDialog = false;
    this.submit = false;
  }
}

