<p-toast></p-toast>
<p-card>
    <div class="container">
        <div class="title">
            <div class="titulo">
                <strong>Reporte de Trazabilidad</strong>
            </div>
        </div>
    </div>
</p-card>
<div style="margin-left: 5%; margin-right: 5%;">
    <p-divider></p-divider>
</div>
<div>
    <div class="containerBody">
        <p-card>
            <form [formGroup]="filterForm">
                <div class="divItemsForm">
                    <mat-form-field appearance="outline">
                        <mat-label>Fecha Inicial</mat-label>
                        <input matInput [matDatepicker]="startDate" formControlName="startDate" [max]="maxDateIni"
                            (dateChange)="setDateFinalMin()">
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                        <mat-error *ngIf="filterFormsControls['startDate'].hasError('required')">
                            La Fecha Inicial es <strong>requerida</strong>
                        </mat-error>
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field appearance="outline">
                        <mat-label>Fecha Final</mat-label>
                        <input matInput [matDatepicker]="endDate" formControlName="endDate" [min]="endDateMin"
                            [max]="maxDateIni">
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                        <mat-error *ngIf="filterFormsControls['endDate'].hasError('required')">
                            La Fecha Final es <strong>requerida</strong>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="divItemsForm">
                    <mat-form-field class="widthFields" appearance="outline">
                        <mat-label>Tipo Documento:</mat-label>
                        <input matInput type="number" placeholder="123456" formControlName="documentTypeId">
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field class="widthFields" appearance="outline">
                        <mat-label>Numero de Identificación:</mat-label>
                        <input matInput type="number" placeholder="123456" formControlName="id">
                    </mat-form-field>
                </div>
                <div class="divItemsForm">
                    <mat-form-field class="widthFields" appearance="outline">
                        <mat-label>Codigo Subtipo Documental:</mat-label>
                        <input matInput type="number" placeholder="123456" formControlName="documentSubtypeId">
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field appearance="outline" class="widthFields">
                        <mat-label>Estado</mat-label>
                        <mat-select formControlName="state">
                            <mat-option value="2">Exitoso</mat-option>
                            <mat-option value="1">Abierto</mat-option>
                            <mat-option value="3">Error</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="divCentrado">
                    <mat-form-field appearance="outline" class="widthFields">
                        <mat-label>Firma</mat-label>
                        <mat-select formControlName="firma">
                            <mat-option value="0">F.Simple</mat-option>
                            <mat-option value="1">F.Compuesta</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <div class="divButtons">
                <button mat-button class="buttonC" (click)="getReport()"
                    [disabled]="filterForm.invalid"
                    [ngStyle]="{'background-color':filterForm.invalid ? '#00000042' :  '#fdda24'}">Consultar</button>
            </div>
        </p-card>
    </div>
</div>

<p-confirmDialog [style]="{width: '450px'}" acceptLabel="Si"></p-confirmDialog>