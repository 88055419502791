import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators'
import { TipoDocument } from 'src/app/core/models/tipo/tipo-document';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  private getAll: string = '/ms-webparametrizador/custodiaApi/documentType/getAllTypes'
  private updateDoc: string = '/ms-webparametrizador/custodiaApi/documentType/updateType'
  private deleteDoc: string = '/ms-webparametrizador/custodiaApi/documentType/delete'
  private createDoc: string = '/ms-webparametrizador/custodiaApi/documentType/createType'

  constructor(private http: HttpClient) { }

  public getAllTipos() {
    return this.http.get(environment.apiUrl + this.getAll).pipe(map(data => data as TipoDocument[]));
  }

  public updateDocType(docType: TipoDocument) {
    const id = docType.docTypeId.toString()
    return this.http.put(environment.apiUrl + this.updateDoc+'/'+id, docType).
      pipe(map(data => data as TipoDocument));
  }

  public deleteDocType(id: number) {
    return this.http.delete(environment.apiUrl + this.deleteDoc+'/'+id.toString()).
      pipe(map(data => data));
  }

  public createDocumentType(docType: TipoDocument) {
    return this.http.post(environment.apiUrl + this.createDoc, docType).
      pipe(map(data => data as TipoDocument));
  }
}
