<p-toast></p-toast>
<p-card>
    <div class="container">
        <div class="title">
            <div class="titulo">
                <strong>Tipos</strong>
            </div>
        </div>
        <div class="table">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                </ng-template>
            </p-toolbar>
            <br>
            <p-table #dt [value]="tiposDocument" [lazy]="true" [loading]="loading" [lazy]="true" [rowHover]="true" dataKey="docTypeId">
                <ng-template pTemplate="header">
                    <tr>
                        <!-- <th>Id Tipo Documento</th> -->
                        <th>Tipo Documento</th>
                        <th>Descripcion</th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Actualizado Por</th>
                        <th>Fecha Actualizacion</th>
                        <th class="buttonTable">Editar</th>
                        <!-- <th class="buttonTable"></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr>
                        <!-- <td>{{doc.docTypeId}}</td> -->
                        <td>{{doc.docType}}</td>
                        <td>{{doc.docTypeDescription}}</td>
                        <td>{{doc.audit.createdBy}}</td>
                        <td>{{doc.audit.creationDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{doc.audit.lastUpdatedBy}}</td>
                        <td>{{doc.audit.lastUpdateDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2"
                                (click)="editDoc(doc)"></button>
                        </td>
                        <!-- <td>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteDoc(doc)"></button>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
<p-dialog [(visible)]="docDialog" [style]="{width: '600px'}" header="Detalle Documento" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-field">
            <label for="docType">Tipo Documento</label>
            <input id="docType" pInputText [(ngModel)]="tipoDocument.docType" required rows="3"
                cols="20"/>
        </div>
        <div class="p-field">
            <label for="docTypeDescription">Descripcion</label>
            <textarea type="text" pInputTextarea id="docTypeDescription" [(ngModel)]="tipoDocument.docTypeDescription" required
                autofocus></textarea>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="cancel()"></button>
        <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="saveDoc()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}" acceptLabel="Si"></p-confirmDialog>