import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ignoreElements } from 'rxjs/operators';
import { Attribute } from 'src/app/core/models/attribute/attribute';
import { MetadataSubtype } from 'src/app/core/models/metadataSubtype/metadata-subtype';
import { SubtipoDocument } from 'src/app/core/models/subtipos/subtipo-document';
import { TipoDocument } from 'src/app/core/models/tipo/tipo-document';
import { DocumentAttributeService } from 'src/app/services/configCustodiaServices/DocumentAttributeService/document-attribute.service';
import { DocumentSubTypeService } from 'src/app/services/configCustodiaServices/DocumentSubTypeService/document-sub-type.service';
import { DocumentTypeService } from 'src/app/services/configCustodiaServices/DocumentTypeService/document-type-service.service';

@Component({
  selector: 'app-subtipo-edit',
  templateUrl: './subtipo-edit.component.html',
  styleUrls: ['./subtipo-edit.component.scss']
})
export class SubtipoEditComponent implements OnInit {

  public subTipoDocument: SubtipoDocument;
  public subTypeId: number;
  public attributeDocuments: Attribute[] = [];
  public attributes: Attribute[] = [];
  public selectedAttributtes: Attribute[] = [];
  public metadataSubtype: MetadataSubtype[] = [];
  public selectedMetadata: MetadataSubtype[] = [];
  public metadataBase: MetadataSubtype;
  public list2: [] = [];
  public docType: string;
  public subtype: string;
  public documentDescription: string;
  public bAddAttribute: boolean = true
  public bDeleteMetadata: boolean = true
  public clonedMetadata: { [s: string]: MetadataSubtype; } = {};
  public editing: boolean = false;
  public dataEmpty: boolean = false;
  public spinner: boolean = true;
  public bSubtipo: boolean = true;
  public documentTypes: TipoDocument[] = [];
  public documentType: TipoDocument;
  public createSubtype: boolean = false;
  public userName: string;

  constructor(private activatedRoute: ActivatedRoute,
    private service: DocumentSubTypeService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: Router,
    private attributeService: DocumentAttributeService,
    private typeService: DocumentTypeService) { }

  ngOnInit(): void {
    this.userName = sessionStorage.getItem('user')
    this.subTypeId = +this.activatedRoute.snapshot.paramMap.get("id");
    if (this.subTypeId > 0) {
      this.getMetadataById(this.subTypeId);
      this.getAttributesById(this.subTypeId);
    } else {
      this.createSubtype = true;
      this.initMetadataBase();
      this.getAllAttributes();
      this.getAllTypes();
    }
  }

  getSubtypoById(id: number) {
    this.service.getSubTypeById(id).subscribe(
      data => {
        this.subTipoDocument = data
        this.docType = this.subTipoDocument.documentType.docType
        this.subtype = this.subTipoDocument.documentSubtypeDesc
        this.documentDescription = this.subTipoDocument.documentDescription
      }
    )
  }

  getMetadataById(id: number) {
    this.service.getMetadataById(id).subscribe(
      data => {
        this.metadataSubtype = data;
        this.metadataBase = data[0]
        this.docType = this.metadataSubtype[0].metadataSubtype.subType.documentType.docType
        this.subtype = this.metadataSubtype[0].metadataSubtype.subType.documentSubtypeDesc
        this.documentDescription = this.metadataSubtype[0].metadataSubtype.subType.documentDescription
        this.spinner = false;
      }
    )
  }

  getAllAttributes() {
    this.attributeService.getAllTipos().subscribe(
      data => {
        this.attributeDocuments = data;
      },
      error => console.log(error)
    )
  }

  getAllTypes() {
    this.typeService.getAllTipos().subscribe(
      data => {
        this.documentTypes = data;
        console.log(this.documentTypes)
        this.spinner = false;
      },
      error => {
        console.log(error)
        this.spinner = false
      }
    )
  }

  initMetadataBase() {
    const date = new Date()
    this.metadataBase = {
      metadataSubtype: {
        attribute: undefined,
        subType: {
          documentDescription: this.documentDescription,
          documentType: this.documentType,
          documentSubtypeDesc: this.subtype,
          subTypeId: undefined,
          audit: {
            createdBy: this.userName,
            creationDate: date,
            lastUpdatedBy: this.userName,
            lastUpdateDate: date
          }
        }
      },
      value: '',
      audit: {
        createdBy: this.userName,
        creationDate: date,
        lastUpdatedBy: this.userName,
        lastUpdateDate: date
      }
    }
  }

  getAttributesById(id: number) {
    this.attributeService.getAttributesById(id).subscribe(
      data => {
        this.attributeDocuments = data
        this.attributeDocuments.sort((a, b) => a.attributteId - b.attributteId)
      },
      error => {
        console.log(error)
      }
    )
  }

  addAttribute() {
    this.selectedAttributtes.forEach(att => {
      let metadata: MetadataSubtype = {
        audit: this.metadataBase.audit,
        value: '',
        metadataSubtype: {
          subType: this.metadataBase.metadataSubtype.subType,
          attribute: undefined
        }
      }
      metadata.metadataSubtype.attribute = att
      this.metadataSubtype.push(metadata)
      let index = this.attributeDocuments.indexOf(att)
      this.attributeDocuments.splice(index, 1)
    })
    this.bAddAttribute = true
    this.selectedAttributtes = []
  }

  validDeleteAttribute() {
    if (this.subTypeId > 0) {
      this.confirmationService.confirm({
        message: 'Estas seguro que quieres eliminar el Atributo?',
        header: 'Confirmar',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          let ids = []
          this.selectedMetadata.forEach(
            data => {
              ids.push(data.metadataSubtype.attribute.attributteId)
            }
          )
          let idSubtipo = this.metadataBase.metadataSubtype.subType.subTypeId
          this.deleteAttribute(ids, idSubtipo);
        }
      })
    } else {
      this.selectedMetadata.forEach(data => {
        let index = this.metadataSubtype.indexOf(data)
        this.metadataSubtype.splice(index, 1)
        this.attributeDocuments.push(data.metadataSubtype.attribute)
      })
      this.selectedMetadata = []
      this.bDeleteMetadata = true
    }
  }

  deleteAttribute(ids: number[], idSubtipo: number) {
    this.spinner = true;
    this.service.deleteAttribute(ids, idSubtipo).subscribe(
      response => {
        this.selectedMetadata.forEach(data => {
          let index = this.metadataSubtype.indexOf(data)
          this.metadataSubtype.splice(index, 1)
          this.attributeDocuments.push(data.metadataSubtype.attribute)
        })
        this.selectedMetadata = []
        this.bDeleteMetadata = true
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'El campo ha sido eliminado exitosamente' });
        this.spinner = false
      },
      error => console.log(error)
    )

  }

  changeAddAttr() {
    this.bAddAttribute = this.selectedAttributtes.length > 0 ? false : true
  }

  changeDeleteAttr() {
    this.bDeleteMetadata = this.selectedMetadata.length > 0 ? false : true
  }

  onRowEditInit(metadata: MetadataSubtype) {
    this.editing = true;
    this.clonedMetadata[metadata.metadataSubtype.attribute.attributteId] = { ...metadata };
  }

  saveMetadata(metadata: MetadataSubtype) {
    if (!this.validarForm()) {
      this.dataEmpty = true;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Existen campos vacios, por favor diligenciar todos los campos con * y de la columna Valor' });
      this.editing = true;
    } else {
      if (this.subTypeId > 0) {
        this.confirmationService.confirm({
          message: 'Estas seguro que quieres Crear ó Actualizar el Atributo?',
          header: 'Confirmar',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.updateMetadata(metadata);
          }
        })
      }
      this.dataEmpty = false;
      delete this.clonedMetadata[metadata.metadataSubtype.attribute.attributteId];
    }
  }

  updateMetadata(metadata: MetadataSubtype) {
    this.spinner = true;
    this.service.updateMetadata(metadata).subscribe(
      response => {
        console.log(response)
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'El campo ha sido creado o actualizado exitosamente' });
        this.spinner = false;
      },
      error => {
        console.log(error)
        this.spinner = false;
      }
    )
  }

  validData(metadata: MetadataSubtype) {
    let valor = metadata.value.trim()
    if (valor == '' || valor == undefined || valor == null) {
      this.dataEmpty = true;
    } else {
      this.dataEmpty = false;
    }
  }

  onRowEditCancel(metadata: MetadataSubtype, index: number) {
    let valor = metadata.value.trim()
    if (valor == '' || valor == undefined || valor == null) {
      this.dataEmpty = true;
    } {
      this.metadataSubtype[index] = this.clonedMetadata[metadata.metadataSubtype.attribute.attributteId];
      delete this.clonedMetadata[metadata.metadataSubtype.attribute.attributteId];
      this.editing = false;
      this.dataEmpty = false;
    }
  }

  guardar() {
    if (this.validarForm()) {
      if (this.subTypeId > 0) {
        this.guardarUpdateSubtype();
      } else {
        this.guardarCreateSubtype();
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Existen campos vacios, por favor diligenciar todos los campos con * y de la columna Valor' });
    }
  }

  guardarUpdateSubtype() {
    this.spinner = true;
    let subtype: SubtipoDocument = {
      audit: this.metadataBase.audit,
      documentDescription: this.documentDescription,
      documentSubtypeDesc: this.subtype,
      documentType: this.metadataBase.metadataSubtype.subType.documentType,
      subTypeId: this.subTypeId
    }
    this.service.updateDocType(subtype).subscribe(
      data => {
        this.spinner = false;
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Subtipo actualizado exitosamente' });
        setTimeout(() => {
          this.route.navigate(['home/config/subtipos']);
        }, 2000);
      },
      error => {
        console.log(error)
        this.spinner = false;
      })
  }

  guardarCreateSubtype() {
    this.spinner = true;
    let subtype: SubtipoDocument = {
      audit: this.metadataBase.audit,
      documentDescription: this.documentDescription,
      documentSubtypeDesc: this.subtype,
      documentType: this.documentType,
      subTypeId: undefined
    }
    this.service.createDocumentSubType(subtype).subscribe(
      data => {
        if (this.validarForm) {
          this.metadataSubtype.forEach(metadata => metadata.metadataSubtype.subType = data)
          this.service.createMetadata(this.metadataSubtype).subscribe(
            data => {
              this.spinner = false;
              this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Subtipo actualizado exitosamente' });
              setTimeout(() => {
                this.route.navigate(['home/config/subtipos']);
              }, 2000);
              console.log(data);
            },
            error => {
              console.log(error)
              this.spinner = false;
            }
          )
        }
      }
    )
  }

  validarForm(): boolean {
    let valid = true;
    if (this.metadataSubtype.length <= 0) {
      return valid = false;
    }
    this.metadataSubtype.forEach(metadata => {
      if (metadata.value == '' || metadata.value == undefined || metadata.value == null) {
        return valid = false;
      }
    })
    if (this.subtype == '' || this.subtype == undefined || this.subtype == null) {
      return valid = false;
    }
    if (this.documentDescription == '' || this.documentDescription == undefined || this.documentDescription == null) {
      return valid = false;
    }
    console.log(valid)
    return valid;
  }

  atras() {
    this.route.navigate(['home/config/subtipos']);
  }

}
