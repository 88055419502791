import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Attribute } from 'src/app/core/models/attribute/attribute';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentAttributeService {

  private getAll: string = '/ms-webparametrizador/custodiaApi/documentAttribute/getAllAttributes';
  private getAllById: string = '/ms-webparametrizador/custodiaApi/documentAttribute/getAttributeById';
  private updateDoc: string = '/ms-webparametrizador/custodiaApi/documentAttribute/updateAttribute';
  private deleteDoc: string = '/ms-webparametrizador/custodiaApi/documentAttribute/delete';
  private createDoc: string = '/ms-webparametrizador/custodiaApi/documentAttribute/createAttribute';

  constructor(private http: HttpClient) { }

  public getAllTipos() {
    return this.http.get(environment.apiUrl + this.getAll).pipe(map(data => data as Attribute[]));
  }

  public updateAttributeType(docAttribute: Attribute) {
    const id = docAttribute.attributteId.toString()
    return this.http.put(environment.apiUrl + this.updateDoc+'/'+id, docAttribute).
      pipe(map(data => data as Attribute));
  }

  public deleteAttributeType(id: number) {
    return this.http.delete(environment.apiUrl + this.deleteDoc+'/'+id.toString()).
      pipe(map(data => data));
  }

  public getAttributesById(id: number) {
    return this.http.get(environment.apiUrl + this.getAllById+'/'+id.toString()).
      pipe(map(data => data as Attribute[]));
  }

  public createAttributeType(docType: Attribute) {
    return this.http.post(environment.apiUrl + this.createDoc, docType).
      pipe(map(data => data as Attribute));
  }
}
