<p-toast></p-toast>
<p-card>
    <div class="container">
        <div class="title">
            <div class="titulo">
                <strong>Subtipos</strong>
            </div>
        </div>
        <br>
        <div *ngIf="spinner; else data" class="spinner">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <ng-template #data>
            <div class="divForm">
                <table>
                    <tr>
                        <th class="tdTitle">
                            <span style="color: red;">*</span> Tipo Documental:&nbsp;
                        </th>
                        <td *ngIf="createSubtype">
                            <p-dropdown [options]="documentTypes" [(ngModel)]="documentType" optionLabel="docType"
                                [filter]="true" filterBy="docType" [showClear]="true" placeholder="Seleccione un Tipo Documento" [style]="{'width': '450px'}">
                                <ng-template pTemplate="selectedItem">
                                    <div class="documentType-item documentType-item-value" *ngIf="documentType">
                                        <div>{{documentType.docType}}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-documentType pTemplate="item">
                                    <div class="documentType-item">
                                        <div>{{documentType.docType}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </td>
                        <td *ngIf="!createSubtype">
                            <input type="text" pInputText placeholder="Tipo Documental" [(ngModel)]="docType" disabled
                                class="inputT">
                        </td>
                    </tr>
                    <tr>
                        <th class="tdTitle">
                            <span style="color: red;">*</span> Subtipo Documental:&nbsp;
                        </th>
                        <td>
                            <input type="text" pInputText placeholder="Subtipo Documental" autoResize="true"
                                [(ngModel)]="subtype" class="inputT">
                        </td>
                    </tr>
                    <tr>
                        <th class="tdTitle">
                            Descripción:
                        </th>
                        <td>
                            <textarea pInputTextarea placeholder="Descripción" [(ngModel)]="documentDescription"
                                class="inputT"></textarea>
                        </td>
                    </tr>
                </table>
            </div>
            <p-divider></p-divider>
            <div class="divButtonsForm">
                <button pButton pRipple type="button" label="Guardar" (click)="guardar()"
                    class="p-button-rounded bGuardar"></button>
                &nbsp;
                <button pButton pRipple type="button" label="Atrás" (click)="atras()"
                    class="p-button-raised p-button-danger p-button-text"></button>
            </div>
            <p-divider></p-divider>
            <div class="pickList">
                <div class="divAttrSelect">
                    <p-table #dt [value]="attributeDocuments" [(selection)]="selectedAttributtes" [rowHover]="true"
                        dataKey="attributteId">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                <h5 class="p-m-0" style="text-align: center;">Atributos a seleccionar</h5>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem">
                                    <p-tableHeaderCheckbox (click)="changeAddAttr()"></p-tableHeaderCheckbox>
                                </th>
                                <th>Atributos</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-attribute>
                            <tr>
                                <td>
                                    <p-tableCheckbox [value]="attribute" (click)="changeAddAttr()"></p-tableCheckbox>
                                </td>
                                <td>{{attribute.attribute}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="divButtons">
                    <div>
                        <button bc-button typeButton="primary" aria-label="Boton" (click)="addAttribute()"
                            [disabled]="bAddAttribute">
                            <bc-icon size="sm" [style.color]="'#00448d'" aria-hidden="true" aria-label="icono">forward
                            </bc-icon>
                        </button>
                    </div>
                    <br>
                    <div>
                        <button bc-button typeButton="primary" aria-label="Boton" (click)="validDeleteAttribute()"
                            [disabled]="bDeleteMetadata">
                            <bc-icon size="sm" [style.color]="'#00448d'" aria-hidden="true" aria-label="icono">reply
                            </bc-icon>
                        </button>
                    </div>
                </div>
                <div class="divMetadataSelect">
                    <p-table #dt2 [value]="metadataSubtype" [rows]="10" [(selection)]="selectedMetadata"
                        dataKey="metadataSubtype.attribute.attributteId" editMode="row">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex p-ai-center p-jc-between">
                                <h5 class="p-m-0" style="text-align: center;">Metadata</h5>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem">
                                    <p-tableHeaderCheckbox (click)="changeDeleteAttr()"></p-tableHeaderCheckbox>
                                </th>
                                <th>Atributo</th>
                                <th colspan="2">Valor</th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-metadata let-editing="editing" let-ri="rowIndex">
                            <tr [pEditableRow]="metadata">
                                <td>
                                    <p-tableCheckbox [value]="metadata" (click)="changeDeleteAttr()"></p-tableCheckbox>
                                </td>
                                <td>
                                    <!-- <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" style="width: 300px;"
                                            [(ngModel)]="metadata.metadataSubtype.attribute.attribute">
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{metadata.metadataSubtype.attribute.attribute}}
                                    </ng-template>
                                </p-cellEditor> -->
                                    {{metadata.metadataSubtype.attribute.attribute}}
                                </td>
                                <td colspan="2">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" (keyup)="validData(metadata)"
                                                style="width: 300px;" [(ngModel)]="metadata.value" required>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{metadata.value}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align:center">
                                    <button *ngIf="!editing" pButton pRipple pInitEditableRow icon="pi pi-pencil"
                                        (click)="onRowEditInit(metadata)"
                                        class="p-button-rounded p-mr-2 bGuardar"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow
                                        icon="pi pi-save" (click)="saveMetadata(metadata)" [disabled]="dataEmpty"
                                        class="p-button-rounded p-button-text p-button-success mr-2"></button>
                                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow
                                        icon="pi pi-times" (click)="onRowEditCancel(metadata, ri)"
                                        [disabled]="dataEmpty"
                                        class="p-button-rounded p-button-danger p-button-text"></button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </ng-template>
    </div>
</p-card>
<p-confirmDialog [style]="{width: '450px'}" acceptLabel="Si"></p-confirmDialog>