export const environment = {
  //LOCAL
  // production: false,
  // apiUrl:"",
  // clientId:"a2734cd8-f459-4e82-8fba-025b748dc3eb",
  // tenantId:"6bb7e37c-042e-4371-a6dd-d42a3fe0afe0",
  // RedirectUrl:"http://localhost:4200/login",
  // GRAPH_ENDPOINT : 'https://graph.microsoft.com/v1.0/me',
  // environment:'local'

  //DEV
  production: true,
  apiUrl:"https://apoyo-al-negocio-int-dev.apps.ambientesbc.com/firma-electronica-corporativa",
  clientId:"884f9885-e7f4-4a53-8a9d-e2458860042a",
  tenantId:"428f4e2e-13bf-4884-b364-02ef9af41a1d",
  RedirectUrl:"https://parametrizador-web-dev.apps.ambientesbc.com/home",
  GRAPH_ENDPOINT : 'https://graph.microsoft.com/v1.0/me',
  environment:'dev'  
};
