import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AtributosComponent } from './home/configCustodia/atributos/atributos.component';
import { SubtipoEditComponent } from './home/configCustodia/subtipos/subtipo-edit/subtipo-edit.component';
import { SubtiposComponent } from './home/configCustodia/subtipos/subtipos.component';
import { TiposComponent } from './home/configCustodia/tipos/tipos.component';
import { TrazabilidadComponent } from './home/firmaElectronica/reportes/trazabilidad/trazabilidad.component';


import { PublicComponent } from './public.component';

const routes: Routes = [
  {
    path: 'home', component: PublicComponent,
    children: [
      { path: 'trazabilidad', component: TrazabilidadComponent },
      { path: 'config/subtipos', component: SubtiposComponent },
      { path: 'config/subtipos/edit/:id', component: SubtipoEditComponent },
      { path: 'config/tipos', component: TiposComponent },
      { path: 'config/atributos', component: AtributosComponent }
    ],
    canActivate: [
      MsalGuard
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }