import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BcAlertModule } from 'bancolombia-design-system/bc-alert';
import { BcAvatarModule } from 'bancolombia-design-system/bc-avatar';
import { BcBreadcrumbModule } from 'bancolombia-design-system/bc-breadcrumb';
import { BcCardModule } from 'bancolombia-design-system/bc-card';
import { BcFooterModule } from 'bancolombia-design-system/bc-footer';
import { BcHeaderModule } from 'bancolombia-design-system/bc-header';
import { BcIconModule } from 'bancolombia-design-system/bc-icon';
import { BcInputModule } from 'bancolombia-design-system/bc-input';
import { BcLinkModule } from 'bancolombia-design-system/bc-link';
import { BcLoaderModule } from 'bancolombia-design-system/bc-loader';
import { BcMenuModule } from 'bancolombia-design-system/bc-menu';
import { BcModalModule } from 'bancolombia-design-system/bc-modal';
import { BcSearchModule } from 'bancolombia-design-system/bc-search';
import { BcSliderModule } from 'bancolombia-design-system/bc-slider';
import { BcStepperModule } from 'bancolombia-design-system/bc-stepper';
import { BcSwitchModule } from 'bancolombia-design-system/bc-switch';
import { BcTableModule } from 'bancolombia-design-system/bc-table';
import { BcTabsModule } from 'bancolombia-design-system/bc-tabs-group';
import { BcTooltipModule } from 'bancolombia-design-system/bc-tooltip';
import { BcButtonModule } from 'bancolombia-design-system/bc-button';
import { BcCheckboxModule } from 'bancolombia-design-system/bc-checkbox';
import { BcRadioModule } from 'bancolombia-design-system/bc-radio';
import { BcPlannerModule } from "bancolombia-design-system/bc-planner";
import { BcShortcutModule } from 'bancolombia-design-system/bc-shortcut';
import { BcBtnGroupModule } from 'bancolombia-design-system/bc-button-group';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CardModule } from 'primeng/card';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { PickListModule } from 'primeng/picklist';
import { MatNativeDateModule } from '@angular/material/core';
import { ListboxModule } from 'primeng/listbox';
import { AutoCompleteModule } from 'primeng/autocomplete';


import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';






@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    CoreRoutingModule,
    ReactiveFormsModule,
    BcAvatarModule,
    BcBreadcrumbModule,
    BcCardModule,
    BcFooterModule,
    BcHeaderModule,
    BcAlertModule,
    BcIconModule.forRoot({
      path: 'https://library-sdb.apps.bancolombia.com/2.0.22',
    }),
    BcInputModule,
    BcLinkModule,
    BcLoaderModule,
    BcMenuModule,
    BcModalModule,
    BcSearchModule,
    BcSliderModule,
    BcStepperModule,
    BcSwitchModule,
    BcTableModule,
    BcTabsModule,
    BcTooltipModule,
    BcButtonModule,
    BcCheckboxModule,
    BcRadioModule,
    BcPlannerModule,
    BcShortcutModule,
    BcBtnGroupModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    CardModule,
    ConfirmDialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    ToolbarModule,
    RatingModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    MatNativeDateModule,
    ProgressSpinnerModule,
    DividerModule,
    PickListModule,
    ListboxModule,
    AutoCompleteModule
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    CoreRoutingModule,
    BcAvatarModule,
    BcBreadcrumbModule,
    BcCardModule,
    BcFooterModule,
    BcHeaderModule,
    BcAlertModule,
    BcInputModule,
    BcLinkModule,
    BcLoaderModule,
    BcMenuModule,
    BcModalModule,
    BcSearchModule,
    BcSliderModule,
    BcStepperModule,
    BcSwitchModule,
    BcTableModule,
    BcTabsModule,
    BcTooltipModule,
    BcButtonModule,
    BcCheckboxModule,
    BcRadioModule,
    BcPlannerModule,
    BcShortcutModule,
    BcBtnGroupModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    CardModule,
    ConfirmDialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    HttpClientModule,
    ToolbarModule,
    RatingModule,
    FormsModule,
    RadioButtonModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputTextareaModule,
    MatNativeDateModule,
    ProgressSpinnerModule,
    DividerModule,
    ReactiveFormsModule,
    PickListModule,
    ListboxModule,
    AutoCompleteModule,

    HeaderComponent,
    SidebarComponent
  ],
  providers: []
})
export class CoreModule { }
