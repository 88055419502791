import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { MetadataSubtype } from 'src/app/core/models/metadataSubtype/metadata-subtype';
import { SubtipoDocument } from 'src/app/core/models/subtipos/subtipo-document';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentSubTypeService {

  private getAll: string = '/ms-webparametrizador/custodiaApi/documentSubType/getAllSubTypes'
  private getById: string = '/ms-webparametrizador/custodiaApi/documentSubType/getSubTypeById'
  private getMetaSubById: string = '/ms-webparametrizador/custodiaApi/metadatasubtype/getAllMetadataById'
  private updateDoc: string = '/ms-webparametrizador/custodiaApi/documentSubType/updateSubType'
  private deleteDoc: string = '/ms-webparametrizador/custodiaApi/documentSubType/delete'
  private updateMeta: string = '/ms-webparametrizador/custodiaApi/metadatasubtype/updateMetadata'
  private deleteMeta: string = '/ms-webparametrizador/custodiaApi/metadatasubtype/deleteMetadata'
  private createDoc: string = '/ms-webparametrizador/custodiaApi/documentSubType/createSubType'
  private createMeta: string = '/ms-webparametrizador/custodiaApi/metadatasubtype/createMetadata'

  constructor(private http: HttpClient) { }

  public getAllTipos() {
    return this.http.get(environment.apiUrl + this.getAll).pipe(map(data => data as SubtipoDocument[]));
  }

  public getSubTypeById(id: number) {
    return this.http.get(environment.apiUrl + this.getById+'/'+id).
      pipe(map(data => data as SubtipoDocument));
  }

  public getMetadataById(id: number) {
    return this.http.get(environment.apiUrl + this.getMetaSubById+'/'+id).
      pipe(map(data => data as MetadataSubtype[]));
  }

  public updateDocType(docSubType: SubtipoDocument) {
    const id = docSubType.subTypeId.toString()
    return this.http.put(environment.apiUrl + this.updateDoc+'/'+id, docSubType).
      pipe(map(data => data as SubtipoDocument));
  }

  public deleteDocType(id: number) {
    return this.http.delete(environment.apiUrl + this.deleteDoc+'/'+id.toString()).
      pipe(map(data => data));
  }

  public updateMetadata(metadataSubtype: MetadataSubtype) {
    return this.http.put(environment.apiUrl + this.updateMeta, metadataSubtype).
      pipe(map(data => data as MetadataSubtype));
  }

  public deleteAttribute(ids: number[], idSubtypo: number){
    let params = new HttpParams()
                    .set('ids', ids.toString())
                    .set('idSubtypo', idSubtypo.toString());
    return this.http.delete(environment.apiUrl + this.deleteMeta, {params})
  }

  public createDocumentSubType(subType: SubtipoDocument) {
    return this.http.post(environment.apiUrl + this.createDoc, subType).
      pipe(map(data => data as SubtipoDocument));
  }

  public createMetadata(metadataSubtypes: MetadataSubtype[]) {
    return this.http.post(environment.apiUrl + this.createMeta, metadataSubtypes).
      pipe(map(data => data as MetadataSubtype[]));
  }
}
