import {EventEmitter, Injectable} from '@angular/core';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {AuthenticationResult} from '@azure/msal-common';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {filter} from 'rxjs/operators';
import {AccountInfo, EventMessage, EventType} from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //public loginShare = new EventEmitter<AuthenticationResult>();
  public loginShare = new EventEmitter<any>();
  public token: string;

  constructor(private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private http: HttpClient) {
  }

  login(): Observable<AuthenticationResult> {
    return this.authService.loginPopup();
  }

  logout() {
    this.authService.logout();
  }

  getAllAccounts() {
    return this.authService.instance.getAllAccounts();
  }

  getToken(): Observable<string> {
    if (this.token) { // Verifies current defined token
      return of(this.token);
    }
    // Resolve from msal
    const accounts = this.getAllAccounts();
    if (accounts.length === 1) {
      const account = accounts[0];
      const accessTokenRequest = {
        scopes: ['user.read'], // TODO: scope verification
        account
      };
      const token = this.getTokenStorage(account);
      if (token) {
        this.token = token;
        return of(this.token);
      }
      // return this.authService.acquireTokenSilent(accessTokenRequest)
      //   .pipe(map(result => result.accessToken));
    }
    return of();
  }

  getTokenStorage(account: AccountInfo): string {
    const claims : any = account.idTokenClaims
    const key = `${account.homeAccountId}-${account.environment}-idtoken-${claims.aud}-${claims.tid}---`;
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item).secret;
    }
    return null;
  }

  listenEvents(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.loginShare.emit(payload);
      });
  }
}

