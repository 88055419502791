import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '../services/authenticationService/authentication.service';
import { HeaderComponent } from '../core/header/header.component';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  
  private token;

  constructor(private router: Router, 
    private authenticationService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authenticationService.getToken() // Retrieves the token from the auth service
      .pipe(mergeMap(token => {
        const headers = req.headers.set('Authorization', `Bearer ${token}`);
        const request = req.clone({headers});
        return next.handle(request)
          .pipe(catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.router.navigateByUrl('/login');
            }
            return throwError(err);
          }));
      }));
  }

}