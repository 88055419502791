import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-trazabilidad',
  templateUrl: './trazabilidad.component.html',
  styleUrls: ['./trazabilidad.component.scss']
})
export class TrazabilidadComponent implements OnInit {

  public filterForm: FormGroup;
  public maxDateIni = new Date();
  public endDateMin;


  constructor(private fb: FormBuilder) { }
  
  ngOnInit(): void {
    this.createForm()
  }

  get filterFormsControls(){
    return this.filterForm.controls;
  }

  createForm(){
    this.filterForm = this.fb.group({
      startDate : [null, Validators.required],
      endDate : [null, Validators.required],
      documentTypeId : [''],
      id : [''],
      documentSubtypeId : [''],
      state : [''],
      firma : ['']
    })
  }

  setDateFinalMin(){
    this.endDateMin = this.filterFormsControls['startDate'].value  
  }

  getReport(){
    console.log(this.filterForm.value)
  }
}
