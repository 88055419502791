import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Attribute } from 'src/app/core/models/attribute/attribute';
import { DocumentAttributeService } from 'src/app/services/configCustodiaServices/DocumentAttributeService/document-attribute.service';

@Component({
  selector: 'app-atributos',
  templateUrl: './atributos.component.html',
  styleUrls: ['./atributos.component.scss']
})
export class AtributosComponent implements OnInit {

  public attributeDocuments: Attribute[] = [];
  public attributeDocument: Attribute;
  public docDialog: boolean = false;
  public submit: boolean = false;
  public loading: boolean = false;
  public userName: string;

  constructor(private service: DocumentAttributeService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.getAll()
    this.userName = sessionStorage.getItem('user')
  }

  getAll() {
    this.loading = true;
    this.service.getAllTipos().subscribe(
      data => {
        console.log(data)
        this.attributeDocuments = data
        this.attributeDocuments.sort((a, b) => a.attributteId - b.attributteId)
        this.loading = false
      },
      error => {
        console.log(error)
        this.loading = false
      }
    )
  }

  editDoc(doc: Attribute) {
    this.attributeDocument = { ...doc }
    this.docDialog = true
  }

  saveAttribute() {
    if (this.attributeDocument.attributteId != null) {
      this.updateAttribute()
    } else {
      this.createAttribute()
    }
  }

  updateAttribute() {
    const date = new Date()
    console.log(date)
    this.attributeDocument.audit.lastUpdatedBy = this.userName
    this.attributeDocument.audit.lastUpdateDate = date
    console.log(this.attributeDocument)
    this.service.updateAttributeType(this.attributeDocument).subscribe(
      data => {
        this.getAll()
      }
      , error => console.log(error)
    )
    this.docDialog = false;
    this.submit = true;
  }

  createAttribute() {
    const date = new Date()
    this.attributeDocument.audit.createdBy = this.userName;
    this.attributeDocument.audit.creationDate = date;
    this.attributeDocument.audit.lastUpdatedBy = this.userName;
    this.attributeDocument.audit.lastUpdateDate = date;
    console.log(this.attributeDocument)
    this.service.createAttributeType(this.attributeDocument).subscribe(
      data => {
        this.getAll()
      }
      , error => console.log(error)
    )
    this.docDialog = false;
    this.submit = true;
  }

  deleteDoc(doc: Attribute) {
    console.log(doc)
    this.confirmationService.confirm({
      message: 'Esta seguro que quiere eliminar el documento ' + doc.description + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.deleteAttributeType(doc.attributteId).subscribe(
          data => {
            console.log(data)
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Tipo Documento eliminado correctamente' });
            this.getAll()
          },
          error => console.log(error)
        )
      }
    })
  }

  openNew() {
    this.attributeDocument = {
      attribute: '',
      description: '',
      audit: {
        createdBy: '',
        creationDate: '',
        lastUpdatedBy: '',
        lastUpdateDate: ''
      }
    }
    this.docDialog = true
  }

  cancel() {
    this.docDialog = false;
    this.submit = false;
  }
}
