<p-toast></p-toast>
<p-card>
    <div class="container">
        <div class="title">
            <div class="titulo">
                <strong>Subtipos</strong>
            </div>
        </div>
        <div class="table">
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                </ng-template>
            </p-toolbar>
            <br>
            <p-table #dt [value]="subTiposDocument" [lazy]="true" [loading]="loading" [rows]="10"
                [rowsPerPageOptions]="[10,25,50]" [rowHover]="true" dataKey="subTypeID">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Id Subtipo Documento</th>
                        <th>Subtipo Documento</th>
                        <th>Descripcion</th>
                        <th>Creado Por</th>
                        <th>Fecha Creación</th>
                        <th>Actualizado Por</th>
                        <th>Fecha Actualizacion</th>
                        <th class="buttonTable">Editar</th>
                        <!-- <th class="buttonTable"></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-doc>
                    <tr>
                        <td>{{doc.subTypeId}}</td>
                        <td>{{doc.documentSubtypeDesc}}</td>
                        <td>{{doc.documentDescription}}</td>
                        <td>{{doc.audit.createdBy}}</td>
                        <td>{{doc.audit.creationDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>{{doc.audit.lastUpdatedBy}}</td>
                        <td>{{doc.audit.lastUpdateDate | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                        <td>
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-mr-2 p-button-warning"
                                (click)="editDoc(doc)"></button>
                        </td>
                        <!-- <td>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                                (click)="deleteDoc(doc)"></button>
                        </td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-card>
<p-confirmDialog [style]="{width: '450px'}" acceptLabel="Si"></p-confirmDialog>