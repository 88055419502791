import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authenticationService/authentication.service';
import { UserAuth } from '../models/userAuth/user-auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public user : UserAuth = new UserAuth()

  constructor(private authenticationService : AuthenticationService) { }

  ngOnInit(): void {
    this.loadUser()
  }

  public setName(data): void {
    if (!this.user) {
      this.user = new UserAuth();
    }
    this.user.name = data.idTokenClaims.name;
    const user: string = data.username;
    const index = user.indexOf('@');
    const username = user.substring(0,index);
    this.user.name = username;
    sessionStorage.setItem('user', this.user.name)
  }

  public loadUser(): void {
    //this.authenticationService.loginShare.subscribe(this.setName);
    this.authenticationService.listenEvents(); // Starts listening auth events
    const accounts = this.authenticationService.getAllAccounts();
    if (accounts.length === 1) {
      const account = accounts[0];
      this.setName(account); // Sets the single one
    }
  }

  logout(){
    this.authenticationService.logout()
  }

}
